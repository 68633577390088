'use strict';

import 'normalize.css';
import '../styles/main.scss';

import toggleMenu from './partials/toggle-menu';
import header from './partials/header';
import localization from './partials/localization';
import './partials/snovioTracking';
import setDefaultProperties from './partials/fixed-banner';

(() => {
  /**
   * @type {object}
   */
  let self = {};
  /**
   * @type {Function}
   */
  self.init = function() {
    toggleMenu?.init();
    localization();
  };
  /**
   * @listens global#DOMContentLoaded
   * @description Event handler for DOMContentLoaded to initialize the page
   */
  document.addEventListener('DOMContentLoaded', () => {
    self.init();
  });
  header.init();
  /**
   * @function fbShare
   * @param {Event} event - The click event triggering the share
   * @param {string} address - The URL to share
   * @description Shares the current page on Facebook
   * @returns {boolean} returns false always
   */
  function fbShare(event, address) {
    event.preventDefault();
    open(
      `http://www.facebook.com/sharer.php?u=${address}`,
      'displayWindow',
      'width=520,height=300,left=350,top=170,status=no,toolbar=no,menubar=no',
    );
    return false;
  }
  /**
   * @function twitterShare
   * @param {Event} event - The click event triggering the share
   * @param {string} address - The URL to share
   * @param {string} text - The text to include in the tweet
   * @description Shares the current page on Twitter
   * @returns {boolean} returns false always
   */
  function twitterShare(event, address, text) {
    event.preventDefault();
    open(
      `http://twitter.com/share?text=${text}&url=${address}`,
      'displayWindow',
      'width=520,height=300,left=350,top=170,status=no,toolbar=no,menubar=no',
    );
    return false;
  }
  /**
   * @type {Function}
   */
  window.fbShare = fbShare;
  /**
   * @type {Function}
   */
  window.twitterShare = twitterShare;

  /**
   * @function addTrackingIdForAppLinks
   * @param {HTMLLinkElement} link - Link register or login
   * @param {Response} data - data what we get from app
   */
  function addTrackingIdForAppLinks(link, data) {
    if (link.search.endsWith('&')) {
      link.search += 'snovTrackingId=' + data.snovTrackingId;
    } else {
      link.search += '&snovTrackingId=' + data.snovTrackingId;
    }
  }

  if (window.snovioTracking) { // it`s broken if we have plugins like https://chrome.google.com/webstore/detail/ublock-origin/cjpalhdlnbpafiamejdnhcphjbkeiagm/related?hl=en-US
    window.snovioTracking.events.subscribe('tracking:completed', function(data) {
      document.querySelectorAll(`a[href*="${appUrl}/register"]`).forEach( (link) => {
        addTrackingIdForAppLinks(link, data);
      });
      document.querySelectorAll(`a[href*="${appUrl}/login"]`).forEach( (link) => {
        addTrackingIdForAppLinks(link, data);
      });
    });
  }

  if (document.querySelector('.fixed-banner')) {
    const header = document.querySelector('#header');
    const banner = document.querySelector('.fixed-banner');

    setDefaultProperties();

    const headMutation = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.contentBoxSize) {
          setDefaultProperties();
        }
      }
    });

    headMutation.observe(header);
    headMutation.observe(banner);
  }
})();
