'use strict';

/**
 * @module localizationModule
 * @description Module for handling localization and language changes
 */

/**
 * @function getCookie
 * @param {string} name - The name of the cookie
 * @returns {?string} The value of the cookie, or null if the cookie is not found
 * @description Retrieves the value of a cookie by its name
 */
function getCookie(name) {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + '=')) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
}
/**
 * @function setCookie
 * @param {string} name - The name of the cookie
 * @param {string} value - The value to set for the cookie
 * @param {number} days - The number of days until the cookie expires
 * @param {string} [domain] - The domain for which the cookie is valid
 * @description Sets a cookie with the given name, value, and expiration days
 */
function setCookie(name, value, days, domain = window.location.hostname) {
  const date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  const expires = '; expires=' + date.toUTCString();
  document.cookie = name + '=' + value + expires + '; path=/; domain=' + domain;
}
/**
 * @function checkPageLanguage
 * @returns {string} The language code ('zh', 'br', 'ua', 'es', or 'en')
 * @description Checks the language of the current page based on the URL
 */
function checkPageLanguage() {
  let br = /\/br/;
  let ua = /\/ua/;
  let es = /\/es/;
  let zh = /\/zh/;

  if (document.location.host === 'snovio.cn' || zh.test(document.location.href)) {
    return 'zh';
  }

  if (br.test(document.location.href)) {
    return 'br';
  }

  if (ua.test(document.location.href)) {
    return 'ua';
  }

  if (es.test(document.location.href)) {
    return 'es';
  }

  return 'en';
}

let pageLanguage = checkPageLanguage();
let websiteLanguage = getCookie('websiteLanguage');
/**
 * @function registerFormLanguage
 * @param {string} lang - The language code to set
 * @description Sets the language of the registration form for back-end processing
 */
function registerFormLanguage(lang) {
  document.addEventListener('DOMContentLoaded', function () {
    let forms = document.querySelectorAll('.form-sign.reg-form');
    if (forms) {
      forms.forEach(function (el) {
        if (el.querySelector('input[name="lang"]')) {
          el.querySelector('input[name="lang"]').value = lang;
        }
      });
    }
  });
}
/**
 * @function setWebsiteLanguage
 * @param {string} language - The language code to set
 * @description Sets the website language for localization
 */
function setWebsiteLanguage(language) {
  if (!language) {
    language = 'en';
  }

  if (!document.querySelector('.footer-bottom__lang-change a')) {
    return;
  }

  registerFormLanguage(language);
  setCookie('websiteLanguage', language, 30);
  const path = document.querySelector(`.footer-bottom__lang-change a[data-lang=${language}]`).href;

  if(language !== pageLanguage) {
    document.location.href = `${path}?lang=${language}`;
  }
}
/**
 * @function $_GET
 * @param {string} key - The key of the query parameter
 * @returns {?string} The value of the query parameter, or false if not found
 * @description Gets the value of a query parameter from the URL
 */
function $_GET(key) {
  var p = window.location.search;
  p = p.match(new RegExp(key + '=([^&=]+)'));
  return p ? p[1] : false;
}
let getCurrentLangFromAdress = $_GET('lang');
/**
 * @function eventForCloseHeader
 * @param {MouseEvent} e - Object of mouse event
 * @description Closes the header language dropdown when clicking outside of it
 */
function eventForCloseHeader(e) {
  if (e.target.closest('.header-content__lang') === null) {
    document.body.querySelector('#header .header-content__lang-change').classList.remove('active');
    document.body.querySelector('.header-content__lang .icon-arrow_down').classList.remove('icon-arrow_down_active');
    document.body.removeEventListener('click', eventForCloseHeader, true);
  }
}
/**
 * @function eventForCloseFooter
 * @param {MouseEvent} e - The click event
 * @description Closes the footer language dropdown when clicking outside of it
 */
function eventForCloseFooter(e) {
  if (e.target.closest('.footer-bottom__lang') === null) {
    document.body.querySelector('#footer .footer-bottom__lang-change').classList.remove('active');
    document.body.querySelector('.footer-bottom__lang .icon-arrow_down').classList.remove('icon-arrow_down_active');
    document.body.removeEventListener('click', eventForCloseFooter, true);
  }
}
/**
 * @function headerLanguageChange
 * @description Sets up the header language change functionality
 */
function headerLanguageChange() {
  if (document.querySelector('#header .header-content__lang')) {
    const headerLanguageBtn = document.querySelector('#header .header-content__lang');
    const languagesContainer = document.body.querySelector('#header .header-content__lang-change');
    headerLanguageBtn.addEventListener('click', function () {
      languagesContainer.classList.toggle('active');
      document.body.querySelector('.header-content__lang .icon-arrow_down').classList.toggle('icon-arrow_down_active');
      document.body.addEventListener('click', eventForCloseHeader, true);
    });
  }
}
/**
 * @function footerLanguageChange
 * @description Sets up the footer language change functionality
 */
function footerLanguageChange() {
  if (document.querySelector('#footer .footer-bottom__lang')) {
    const footerLanguageBtn = document.querySelector('#footer .footer-bottom__lang');
    const footerLanguagesContainer = document.body.querySelector('#footer .footer-bottom__lang-change');
    footerLanguageBtn.addEventListener('click', function () {
      footerLanguagesContainer.classList.toggle('active');
      footerLanguageBtn.classList.toggle('wrapper-active');
      document.body.querySelector('.footer-bottom__lang .icon-arrow_down').classList.toggle('icon-arrow_down_active');
      document.body.addEventListener('click', eventForCloseFooter, true);
    });
  }
}
let languageChangeButtons = document.querySelectorAll('.language-change a');
/**
 * @function setLanguageChanger
 * @description Sets up language change functionality for buttons
 */
function setLanguageChanger() {
  for (let button of languageChangeButtons) {
    button.addEventListener('click', function (event) {
      event.preventDefault();
      let idOfTheButton = event.target.dataset.lang;
      setWebsiteLanguage(idOfTheButton);
    });
  }
  headerLanguageChange();
  footerLanguageChange();
}

/**
 *
 */
function localization () {
  if (getCurrentLangFromAdress) {
    setWebsiteLanguage(getCurrentLangFromAdress);
  } else if (websiteLanguage) {
    setWebsiteLanguage(websiteLanguage);
  }
  setLanguageChanger();
}

export default localization;
