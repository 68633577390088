'use strict';

/**
 * @module toggleMenuModule
 * @description Toggle menu for mobile header and footer
 */

import { gsap } from 'gsap';

const toggleMenu = (function () {

  if (!document.querySelector('.header-content__menu')) {
    return;
  }

  const bodyTag = document.querySelector('body'),
    htmlTag = document.querySelector('html'),
    icnMenu = document.querySelector('.header-box__mobile-menu'),
    header = document.querySelector('#header'),
    navigation = document.querySelector('.header-content__menu'),
    listItem = navigation.querySelectorAll('.header-content__menu-item:not(.just-link)'),
    footerColsTitle = document.querySelectorAll('.footer-top__col .footer-top__col-title');

  /**
   *
   */
  function toggleMenuRun() {
    /**
     * @function toggleFooterColMenu
     * @param {MouseEvent} e - Object of mouse event
     * @description Toggle footer items
     */
    const toggleFooterColMenu = (e) => {
      if(window.innerWidth <= 1148) {
        const currentTarget = e.currentTarget,
          nexElement = currentTarget.nextElementSibling,
          isActive = currentTarget.classList.contains('active');

        if (!isActive) {
          gsap.set(nexElement, {
            height: 0,
            display: 'block',
            overflow: 'hidden'
          });
        }

        currentTarget.classList.toggle('active');

        gsap.to(nexElement, {
          duration: 0.3,
          ease: isActive ? 'power1.out' : 'power1.inOut',
          height: isActive ? 0 : 'auto',
          marginTop: isActive ? 0 : 12,
          onComplete: () => {
            if (isActive) {
              nexElement.style.display = 'none';
            }

            nexElement.classList.toggle('active');
          }
        });
      }
    };
    footerColsTitle.forEach(el => {
      el.addEventListener('click', toggleFooterColMenu);
    });
    /**
     * @function toggleMobileMenu
     * @description Toggle mobile menu and close menu items
     */
    function toggleMobileMenu () {
      icnMenu.classList.toggle('active');
      header.classList.toggle('active-mobile-menu');
      bodyTag.classList.toggle('parent-overflow');
      htmlTag.classList.toggle('parent-overflow');
      listItem.forEach(el => {
        if (el.classList.contains('toggle-submenu')) {
          el.classList.remove('toggle-submenu');
          el.querySelector('.header-content__submenu')._tween.reverse();
        }
      });
    }
    icnMenu.addEventListener('click', toggleMobileMenu);
    /**
     * @function toggleSubmenu
     * @param {MouseEvent} e - Object of mouse event
     * @description Toggle submenu
     */
    const toggleSubmenu = (e) => {
      if(document.querySelector('.active-mobile-menu')) {
        const currentTarget = e.currentTarget,
          isActive = currentTarget.classList.contains('toggle-submenu'),
          submenu = currentTarget.querySelector('.header-content__submenu');

        let tween = submenu._tween;

        if (!tween) {
          tween = gsap.to(submenu, {
            duration: 0.55,
            ease: isActive ? 'power1.out' : 'power1.inOut',
            height: 'auto',
            paused: true,
            onReverseComplete: () => {
              gsap.set(submenu, {height: ''});
            }
          });
          submenu._tween = tween;
        }

        if (!isActive) tween.play();

        if (isActive) tween.reverse();

        e.currentTarget.classList.toggle('toggle-submenu');
      }
    };
    listItem.forEach(el => {
      el.addEventListener('click', toggleSubmenu);
    });
  }

  /**
   * @function triggerMobMenu
   * @description Close mobile menu
   */
  function triggerMobMenu() {
    if(window.innerWidth >= 1149) {
      if(header.classList.contains('active-mobile-menu')
        && bodyTag.classList.contains('parent-overflow')
        && htmlTag.classList.contains('parent-overflow')
        && icnMenu.classList.contains('active')
      )
      {
        icnMenu.click();
        if (document.querySelector('.active-mobile-menu')) {
          listItem.forEach(el => {
            if (el.classList.contains('toggle-submenu')) {
              el.querySelector('.header-content__submenu')._tween.reverse();
              el.classList.remove('toggle-submenu');
            }
          });
        }
      }
    }
  }

  return {
    /**
     * @function init
     * @description Initializes menu-related functionality
     * @returns {void} - return nothing
     */
    init() {
      toggleMenuRun();
      triggerMobMenu();
      window.addEventListener('resize', () => {
        triggerMobMenu();
      });
    }
  };
})();
export default toggleMenu;