/**
 * Initializes user tracking by sending a POST request with tracking data.
 * @returns {void}
 */
function userTracking() {
  /**
   * Handles successful response from the tracking POST request.
   * @param {Response} response - The response data.
   * @returns {void}
   */
  function handleSuccess(response) {
    const expireDate = new Date();
    expireDate.setFullYear(expireDate.getFullYear() + 5);

    setCookie('snovTrackingId', response.snovTrackingId, { expires: expireDate, Secure: true });

    window.snovioTracking.events.emit('tracking:completed', response);
  }

  /**
   * Handles error response from the tracking POST request.
   * @param {Error} error - The error object.
   * @returns {void}
   */
  function handleError(error) {
    window.snovioTracking.events.emit('tracking:completed');
    console.error(error);
  }

  /**
   * Sends a POST request for user tracking.
   * @returns {void}
   */
  function sendTrackingRequest() {
    fetch(`https://${window.appUrl}/back/track-first-visit`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        snovTrackingId: getCookie('snovTrackingId'),
        url: window.location.href,
        referer: document.referrer ? document.referrer : `https://${window.appUrl}`,
      }),
    })
      .then(response => response.json())
      .then(handleSuccess)
      .catch(handleError);
  }

  // Send the tracking request
  sendTrackingRequest();
}

/**
 * Gets the value of a cookie by name.
 * @param {string} name - The name of the cookie.
 * @returns {string|null} - The value of the cookie or null if not found.
 */
function getCookie(name) {
  const matches = document.cookie.match(new RegExp(`(?:^|; )${name.replace(/([$?*|{}()[\]\\+^])/g, '\\$1')}=([^;]*)`));

  return matches ? decodeURIComponent(matches[1]) : null;
}

/**
 * Sets a cookie with the provided name, value, and options.
 * @param {string} name - The name of the cookie.
 * @param {string} value - The value to set for the cookie.
 * @param {object} props - Additional properties for the cookie.
 * @returns {void}
 */
function setCookie(name, value, props) {
  props = props || {};
  let exp = props.expires;

  if (typeof exp === 'number' && exp) {
    const d = new Date();
    d.setTime(d.getTime() + exp * 1000);
    exp = d;
    props.expires = d;
  }

  if (exp && exp.toUTCString) {
    props.expires = exp.toUTCString();
  }

  value = encodeURIComponent(value);

  let updatedCookie = `${name}=${value}`;

  Object.keys(props).forEach(propName => {
    updatedCookie += `; ${propName}`;

    const propValue = props[propName];

    if (propValue !== true) {
      updatedCookie += `=${propValue}`;
    }
  });

  document.cookie = updatedCookie;
}

/**
 * Event emitter class for handling custom events.
 */
class EventEmitter {
  /**
   * Constructs an EventEmitter instance.
   */
  constructor() {
    this.events = {};
  }

  /**
   * Emits an event with optional data.
   * @param {string} eventName - The name of the event to emit.
   * @param {*} data - Optional data to pass to event handlers.
   * @returns {void}
   */
  emit(eventName, data) {
    const event = this.events[eventName];
    if (event) {
      event.forEach(fn => {
        fn.call(null, data);
      });
    }
  }

  /**
   * Subscribes a function to an event.
   * @param {string} eventName - The name of the event to subscribe to.
   * @param {Function} fn - The function to be called when the event is emitted.
   * @returns {Function} - A function to unsubscribe from the event.
   */
  subscribe(eventName, fn) {
    if (!this.events[eventName]) {
      this.events[eventName] = [];
    }

    this.events[eventName].push(fn);
    return () => {
      this.events[eventName] = this.events[eventName].filter(eventFn => fn !== eventFn);
    };
  }
}

// Initialize snovioTracking
window.snovioTracking = {
  events: new EventEmitter(),
};

userTracking();