'use strict';

/**
 * @function setMainPaddingForBody
 * @description Sets padding for the body based on the heights of the header and banner elements.
 * This function calculates the combined height of the header and banner,
 * then sets CSS variables for padding and header position to style the page layout dynamically.
 */
function setMainPaddingForBody () {
  const header = document.querySelector('#header');
  const banner = document.querySelector('.fixed-banner');

  let headerHeight = header.offsetHeight;
  let bannerHeight = banner.offsetHeight;


  document.documentElement.style.setProperty('--padding-top-banner', headerHeight + bannerHeight + 'px');
  document.documentElement.style.setProperty('--header-top-position', bannerHeight + 'px');
}

export default setMainPaddingForBody;

