'use strict';

/**
 * @module headerModule
 * @description Header module for handling header interactions
 */
const header = (function() {
  if (!document.querySelector('.header-content__menu')) {
    // return empty init - when no header menu
    return {
      /**
       * @returns {void} - returns nothing
       */
      init() {},
    };
  }

  /**
   * Reference to the header element
   * @type {HTMLElement}
   */
  const header = document.getElementById('header');

  /**
   * Reference to the navigation menu
   * @type {HTMLElement}
   */
  const navigation = document.querySelector('.header-content__menu');

  /**
   * Collection of list items in the navigation menu
   * @type {NodeList}
   */
  const listItems = navigation.querySelectorAll('.header-content__menu-item:not(.just-link)');

  /**
   * Reference to the line indicator element
   * @type {HTMLElement}
   */
  const line = document.querySelector('.header-content__menu-line');

  /**
   * @function positionIndicator
   * @param {HTMLElement} el - The element to position the indicator
   * @description Positions the indicator based on the provided element
   */
  function positionIndicator(el) {
    if (el) {
      const left = el.offsetLeft;
      const width = el.offsetWidth;
      line.style.left = `${left}px`;
      line.style.width = `${width}px`;
    }
  }
  /**
   * @function headerState
   * @param {string} event - The event type ('mouseenter' or 'mouseleave')
   * @description Handles the header state based on the event
   */
  function headerState(event) {
    if (
      event === 'mouseenter' &&
      !header.classList.contains('active-submenu') &&
      !header.classList.contains('active-scroll')
    ) {
      header.classList.add('active-submenu');
    }
    if (
      event === 'mouseleave' &&
      header.classList.contains('active-submenu') &&
      !header.classList.contains('active-scroll')
    ) {
      header.classList.remove('active-submenu');
    }
  }
  /**
   * @function mouseEnterCallback
   * @description Callback for item menu when cursor enter
   */
  function mouseEnterEvent () {
    if (!header.classList.contains('active-mobile-menu')) {
      this.classList.add('active');
      listItems.forEach((sibling) => {
        if (sibling !== this) {
          sibling.classList.remove('active');
        }
      });
      line.classList.add('line-active');
      positionIndicator(this);
      headerState('mouseenter');
    }
  }
  /**
   * @function mouseLeaveCallback
   * @description Callback for item menu when cursor leave
   */
  function mouseLeaveEvent ()  {
    if (!header.classList.contains('active-mobile-menu')) {
      this.classList.remove('active');
      line.classList.remove('line-active');
      headerState('mouseleave');
    }
  }
  /**
   * @function wheelCallback
   * @param {Event} e - The wheel event
   * @description Handles wheel event for the navigation menu
   */
  function wheelCallback (e) {
    let scrollTo = null;
    if (e.type === 'wheel') {
      scrollTo = e.deltaY;
    }
    if (scrollTo && window.innerWidth >= 1149) {
      e.preventDefault();
      this.scrollTop += scrollTo;
    }
  }
  /**
   * @function headerScrollHide
   * @description Changes header class on scroll for background color
   */
  function headerScrollHide() {
    if (header) {
      if (window.scrollY > 0 && !header.classList.contains('active-scroll')) {
        header.classList.add('active-scroll');
      }
      window.addEventListener('scroll', function () {
        const scrolled = window.pageYOffset || document.documentElement.scrollTop;
        if (scrolled > 0) {
          header.classList.add('active-scroll');
        } else {
          header.classList.remove('active-scroll');
        }
      });
    }
  }

  if(document.querySelector('.header-content__menu')) {
    // Event listeners for list items
    listItems.forEach((item) => {
      item.addEventListener('mouseenter', mouseEnterEvent);
      item.addEventListener('mouseleave', mouseLeaveEvent);
    });
    navigation.addEventListener('wheel', wheelCallback);

    return {
      /**
       * Initializes the header module
       */
      init() {
        window.addEventListener('load', function () {
          positionIndicator(document.querySelector('.header-content__menu-item:first-child'));
        });
        window.addEventListener('resize', () => positionIndicator(document.querySelector('.header-content__menu-item.active')));
        headerScrollHide();
      },
    };
  }
})();
export default header;
